import { useEffect, useState } from 'react'

import { checkCognitoSsoByDefault } from './checkFeatureFlag'
import { useFetch } from './fetch/useFetch'

export const cpDomain =
  (window.CoderPad?.ENVIRONMENT ?? '') === 'production'
    ? 'https://coderpad.io'
    : 'https://coderpad-staging.io'

export const cgBaseUrl = window.CoderPad?.CODINGAME_BASE_URL

export const getIsCgUser = async (): Promise<boolean> => {
  if (window.CoderPad.ENVIRONMENT === 'development') {
    return false
  }

  const cgUserRes: Response = await fetch('/is_codingame_user', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
  })

  return await cgUserRes.json()
}

export interface CodingameCognitoSsoConfig {
  xmlConfigFile?: string
  metadataUrl: string
  identityProviderName?: string
  cognitoClientId?: string
  numberOfPendingLinks: number
  numberOfErrorLinks: number
  scimUrl: string
  scimAuthToken: string
  spEntityId: string
  spAssertionConsumerUrl: string
  idpLoginUrl: string
}

export const useCodingameCognitoSsoConfig = () => {
  const [config, setConfig] = useState<CodingameCognitoSsoConfig>()
  const [pending, setPending] = useState(true)
  const cognitoSsoByDefault = checkCognitoSsoByDefault()

  useEffect(() => {
    if (!cognitoSsoByDefault) {
      setConfig(undefined)
      setPending(false)
      return
    }

    const fetchConfig = async () => {
      setPending(true)
      try {
        const response = await fetch('/cognito_sso_configuration')
        setConfig(await response.json())
      } catch (e) {
        setConfig(undefined)
      } finally {
        setPending(false)
      }
    }

    fetchConfig()
  }, [cognitoSsoByDefault])

  return [config, pending] as const
}

export const useUpdateCodingameCognitoSsoConfig = () => {
  const fetcher = useFetch()

  return async (
    xmlConfigFile: string,
    ssoEnforced: boolean,
    loginSubdomain?: string
  ): Promise<CodingameCognitoSsoConfig> => {
    const response = await fetcher('/cognito_sso_configuration', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ xmlConfigFile, ssoEnforced, loginSubdomain }),
    })

    return response.json()
  }
}
