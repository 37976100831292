import * as Sentry from '@sentry/browser'
import React from 'react'

interface IErrorBoundaryProps {
  fallback: (error?: Error) => React.ReactNode
  extraTags?: Record<string, string>
}
interface IErrorBoundaryState {
  error?: Error
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  public state = {
    error: undefined,
  }

  componentDidCatch(error: Error, errorInfo: { [key: string]: any }) {
    const tags = {
      layer: 'react',
      ...this.props.extraTags,
    }
    if (window.CoderPad.ENVIRONMENT === 'development') {
      console.error('React error', error, tags)
    } else {
      Sentry.captureException(error, { tags, extra: errorInfo })
    }
  }

  render() {
    return <>{this.state.error ? this.props.fallback(this.state.error) : this.props.children}</>
  }
}
